<template>
  <v-dialog v-model="open" max-width="800" scrollable>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <template>
          {{ $t('table.saved_filters') }}
        </template>
        <v-btn icon @click="open = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0 ma-0">
        <v-row no-gutters>
          <v-col>
            <v-list max-height="500px" style="overflow: auto">
              <div v-for="(filter, index) in datatable_filters" :key="index">
                <v-list-item @click="() => previewSavedFilter(filter)">
                  <v-col> - {{ filter.name }}</v-col>
                  <v-col cols="auto">
                    <v-btn class="mr-2" large icon color="primary" @click.stop="() => applySavedFilter(filter)">
                      <v-icon large class="mx-2">{{ mdiPlayCircleOutline }}</v-icon>
                    </v-btn>
                    <v-btn icon @click.stop="() => deleteSavedFilter(filter)">
                      <v-icon>{{ mdiDelete }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-list-item>
                <v-divider></v-divider>
              </div>
              <v-list-item v-if="datatable_filters.length == 0">
                {{ $t('table.no_data') }}
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="auto">
            <v-divider vertical />
          </v-col>
          <v-col>
            <div class="pt-5 px-5">{{ $t('table.filter_create_description') }}</div>
            <v-text-field v-model="saved_filter_name" hide-details="auto" :label="$t('label.name')" class="pa-5" />

            <v-divider />
            <div style="max-height: 400px; overflow:auto">
              <filters :header_items="local_header_items" />
            </div>
            <div class="pa-5">
              <v-btn @click="saveFilter" block color="primary" :disabled="saved_filter_name == ''">{{
                $t('common.save')
              }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiDelete, mdiPlayCircleOutline } from '@mdi/js'
import Filters from '@/components/Datatable/Filters.vue'

export default {
  name: 'SavedFiltersDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
    header_items: {
      type: Array,
      required: true,
    },
    parrent_callback: {
      type: Function,
      required: true,
    },
  },

  components: {
    Filters,
  },

  created() {
    this.header_items.forEach(function (header) {
      if (header.search_type) {
        if (!header.search_value) {
          Vue.set(header, 'search_value', { 0: '', 1: '' })
        }
        if (!header.search_operator) {
          if (header.search_type === 'text') {
            Vue.set(header, 'search_operator', 'like')
          } else if (header.search_type === 'enum') {
            Vue.set(header, 'search_operator', 'in')
          } else {
            Vue.set(header, 'search_operator', 'eq')
          }
        }
      }
    })
    this.local_header_items = this.header_items
    this.$http('datatable_filters/index?entity=' + this.entity).then(res => {
      this.datatable_filters = res.data
    })
  },

  data: () => {
    return {
      open: false,
      saved_filter_name: '',
      datatable_filters: [],
      mdiClose,
      mdiDelete,
      mdiPlayCircleOutline,
      local_header_items: [],
    }
  },
  methods: {
    saveFilter() {
      let filters = []
      this.local_header_items.forEach(header => {
        if (header.search_value) {
          if (header.search_value[0] || header.search_value[1]) {
            filters.push({
              identifier: header.value,
              value: header.search_value,
              operator: header.search_operator,
            })
          }
        }
      })

      this.$http
        .post('datatable_filters/create', {
          entity: this.entity,
          name: this.saved_filter_name,
          filters: filters,
        })
        .then(res => {
          this.datatable_filters = res.data
          this.local_header_items.forEach(header => {
            if (header.search_value) {
              header.search_value[0] = ''
              header.search_value[1] = ''
            }
          })
          this.saved_filter_name = ''
        })
    },
    applySavedFilter(saved_filter) {
      this.open = false
      this.parrent_callback(saved_filter)
    },
    previewSavedFilter(saved_filter) {
      let filters = JSON.parse(saved_filter.value)
      this.saved_filter_name = saved_filter.name
      this.local_header_items.forEach(header => {
        filters.forEach(filter => {
          if (header.search_type) {
            if (header.search_value) {
              header.search_value[0] = ''
              header.search_value[1] = ''
            }
            if (header.value == filter.identifier) {
              header.search_value[0] = filter.value[0]
              header.search_value[1] = filter.value[1]
              header.search_operator = filter.operator
            }
          }
        })
      })
    },
    deleteSavedFilter(filter) {
      this.$http.post('datatable_filters/delete/' + filter.id, { entity: this.entity }).then(res => {
        this.datatable_filters = res.data
      })
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.open = newVal
        this.saved_filter_name = ''
        this.local_header_items.forEach(header => {
          if (header.search_value) {
            header.search_value[0] = ''
            header.search_value[1] = ''
          }
        })
      },
    },
    open: {
      handler(newVal) {
        if (!newVal) {
          this.$emit('input', newVal)
        }
      },
    },
  },
}
</script>

<style>
</style>