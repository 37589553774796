<template>
  <v-expansion-panels accordion flat class="elevation-0">
    <template v-for="header in header_items">
      <v-expansion-panel v-if="header.search_type" :key="header.value">
        <v-expansion-panel-header>
          <v-row align="center">
            <v-col cols="auto">
              <v-icon small :color="header.search_value[0] ? 'primary' : ''">{{ mdiCheckboxBlankCircle }}</v-icon>
            </v-col>
            <v-col cols="auto"> {{ $t(header.text) }} </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div>
            <v-row v-if="header.search_type === 'text'">
              <v-col cols="auto" align-self="center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs">
                      <v-icon>{{ getIcon(header.search_operator) }} </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="item in text_filters"
                      :key="item.value"
                      @click="header.search_operator = item.value"
                    >
                      <v-icon class="mr-2">{{ getIcon(item.value) }}</v-icon>
                      {{ $t(item.text) }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col>
                <v-text-field v-model="header.search_value[0]" :placeholder="$t('table.type_here')" type="text" />
              </v-col>
            </v-row>

            <v-row v-else-if="header.search_type === 'number'">
              <v-col cols="auto" align-self="center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs">
                      <v-icon>{{ getIcon(header.search_operator) }} </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="item in number_filters"
                      :key="item.value"
                      @click="header.search_operator = item.value"
                    >
                      <v-icon class="mr-2">{{ getIcon(item.value) }}</v-icon>
                      {{ $t(item.text) }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col>
                <v-text-field v-model="header.search_value[0]" :placeholder="$t('table.type_here')" type="number" />

                <v-text-field
                  v-if="header.search_operator === 'range'"
                  v-model="header.search_value[1]"
                  :placeholder="$t('table.type_here')"
                  type="number"
                />
              </v-col>
            </v-row>

            <v-row v-else-if="header.search_type === 'date'">
              <v-col cols="auto" align-self="center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs">
                      <v-icon>{{ getIcon(header.search_operator) }} </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="item in date_filters"
                      :key="item.value"
                      @click="header.search_operator = item.value"
                    >
                      <v-icon class="mr-2">{{ getIcon(item.value) }}</v-icon>
                      {{ $t(item.text) }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col>
                <date-picker v-model="header.search_value[0]" />
                <div v-show="header.search_operator == 'range'">
                  <date-picker v-show="header.search_operator == 'range'" v-model="header.search_value[1]" />
                </div>
              </v-col>
            </v-row>

            <v-row v-else-if="header.search_type === 'enum'">
              <v-col>
                <v-select
                  v-model="header.search_value[0]"
                  :items="header.enum_options"
                  :item-text="item => item.label"
                  item-value="value"
                  hide-details="auto"
                  chips
                  multiple
                />
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
        <v-divider></v-divider>
      </v-expansion-panel>
    </template>
  </v-expansion-panels>
</template>

<script>
import {
  mdiCodeEqual,
  mdiCodeGreaterThan,
  mdiCodeLessThan,
  mdiApproximatelyEqualBox,
  mdiMathNormBox,
  mdiFilterOutline,
  mdiCheckboxBlankCircle,
} from '@mdi/js'
import DatePicker from '@/components/DatePicker.vue'

export default {
  name: 'Filters',

  components: {
    DatePicker,
  },

  props: {
    //Functie die afgaat wanneer op een item wordt geklikt
    header_items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      text_filters: [
        { text: 'table.equal', value: 'eq' },
        { text: 'table.like', value: 'like' },
      ],
      number_filters: [
        { text: 'table.equal', value: 'eq' },
        { text: 'table.greater_than', value: 'gt' },
        { text: 'table.less_than', value: 'lt' },
        { text: 'table.between', value: 'range' },
      ],
      date_filters: [
        { text: 'table.equal', value: 'eq' },
        { text: 'table.greater_than', value: 'gt' },
        { text: 'table.less_than', value: 'lt' },
        { text: 'table.between', value: 'range' },
      ],

      mdiCodeEqual,
      mdiCodeGreaterThan,
      mdiCodeLessThan,
      mdiApproximatelyEqualBox,
      mdiMathNormBox,
      mdiCheckboxBlankCircle,
    }
  },
  methods: {
    getIcon(operator) {
      var icon = ''
      switch (operator) {
        case 'eq':
          icon = mdiCodeEqual
          break
        case 'gt':
          icon = mdiCodeGreaterThan
          break
        case 'lt':
          icon = mdiCodeLessThan
          break
        case 'like':
          icon = mdiApproximatelyEqualBox
          break
        case 'range':
          icon = mdiMathNormBox
          break
        case 'filter':
          icon = mdiFilterOutline
          break
      }
      return icon
    },
  },
}
</script>

<style>
</style>