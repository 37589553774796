<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="computedDateFormatted" :placeholder="$t('table.click_here')" readonly v-bind="attrs" v-on="on"></v-text-field>
    </template>

    <v-date-picker v-model="date" no-title color="primary" @input="menu = false"></v-date-picker>
  </v-menu>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref, watch } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'

export default {
  emits: ['input'],

  props: {
    value: String,
  },
  setup(props, { emit }) {
    const date = ref('')
    const menu = ref(false)
    date.value = props.value

    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}/${month}/${year}`
    }

    let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))

    const computedDateFormatted = computed(() => {
      if (date.value) {
        return formatDate(date.value)
      } else {
        return ''
      }
    })

    watch(date, () => {
      emit('input', date.value)
      dateFormatted = formatDate(date.value)
    })

    watch(() => props.value, () => {
      date.value = props.value
    })

    return {
      date,
      dateFormatted,
      menu,
      computedDateFormatted,
      formatDate,

      // icons
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
