<template>
  <v-dialog v-model="open" max-width="550">
    <v-card class="py-8">
      <v-row no-gutters justify="center">
        <v-col cols="auto">
          <v-icon size="100">{{ mdiAlertCircleOutline }}</v-icon>
        </v-col>
      </v-row>
      <v-card-title class="text-center justify-center text-h5 px-5" style="word-break: break-word">
        <i18n tag="div" path="dialog.delete_title">
          <template v-slot:type>
            <b class="bold">{{ type.toLowerCase() }}</b>
          </template>
          <template v-slot:label>
            <span>{{ identifier_label.toLowerCase() }}</span>
          </template>
          <template v-slot:value>
            <b>{{ value_copy }}</b>
          </template>
        </i18n>
      </v-card-title>
      <v-row class="pa-0 ma-0" justify="center">
        <v-col cols="auto">
          <v-btn @click="deleteOnClick" class="error">{{ $t('common.delete') }}</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn @click="$emit('input', false)">{{ $t('common.cancel') }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiAlertCircleOutline } from '@mdi/js'

export default {
  name: 'DeleteDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    deleteOnClick: {
      type: Function,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    identifier_label: {
      type: String,
      required: true,
    },
    identifier_value: {
      required: true,
    },
  },
  mounted() {
    //this prevent the text to disapear when the items gets deleted
    this.value_copy = this.identifier_value
  },

  data: () => {
    return {
      open: false,
      value_copy: '',
      mdiAlertCircleOutline: mdiAlertCircleOutline,
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.open = newVal
      },
    },
    open: {
      handler(newVal) {
        if (!newVal) {
          this.$emit('input', newVal)
        }
      },
    },
    identifier_value: {
      handler(newVal) {
        if (newVal) {
          this.value_copy = newVal
        }
      },
    },
  },
}
</script>